<template>
    <ContextMeterBase :percentage="state.percentage" :current-checkpoint="state.activeCheckpoint" />
</template>

<script setup>
import ContextMeterBase from "~vue/Onboarding/ContextMeterBase.vue";
import { computed } from "vue";

const props = defineProps({
    text: { type: String, default: "" },
});

const TEXT_CHECKPOINTS = [25, 100, 250, 350];
const state = computed(() => {
    const textLength = props.text.length;

    if (textLength === 0) {
        return { activeCheckpoint: -1, percentage: 1 };
    }

    let activeCheckpoint = -1;
    const percentage = Math.min((textLength / TEXT_CHECKPOINTS[TEXT_CHECKPOINTS.length - 1]) * 100, 100);

    if (textLength > 0 && textLength <= TEXT_CHECKPOINTS[0]) {
        activeCheckpoint = -1;
    } else if (textLength > TEXT_CHECKPOINTS[0] && textLength <= TEXT_CHECKPOINTS[1]) {
        activeCheckpoint = 0;
    } else if (textLength > TEXT_CHECKPOINTS[1] && textLength <= TEXT_CHECKPOINTS[2]) {
        activeCheckpoint = 1;
    } else if (textLength > TEXT_CHECKPOINTS[2] && textLength <= TEXT_CHECKPOINTS[3]) {
        activeCheckpoint = 2;
    } else if (textLength > TEXT_CHECKPOINTS[3]) {
        activeCheckpoint = 3;
    }

    return { activeCheckpoint, percentage: Math.min(percentage, 100) };
});
</script>
