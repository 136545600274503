<template>
    <BaseDialog v-if="showSkipDialog" @cancel="showSkipDialog = false" @close="showSkipDialog = false">
        <div class="flex flex-col items-center text-center rounded-xl py-10 px-6 w-full md:max-w-[500px] gap-6 border-2 border-[#F5F5F5] shadow-lg bg-white">
            <div class="w-32 h-32">
                <CoachingModeMarble />
            </div>
            <h3 class="heading-3">Skip getting to know Nadia?</h3>
            <p class="body-1 text-[#8C8C8C]">You won't get a fully tailored version of Nadia right now—but that's okay!</p>
            <p class="body-1 text-[#8C8C8C]">You can always come back and complete this, and Nadia will continue to learn about you over time.</p>
            <div class="w-full flex flex-col items-center gap-4 mt-6">
                <BaseButton class="w-2/5" theme="primary" @click="showSkipDialog = false">Continue</BaseButton>
                <BaseButton data-cy="skip-personalization-dialog" class="w-2/5" theme="secondary" @click="handleSkip">Skip</BaseButton>
            </div>
        </div>
    </BaseDialog>
    <Transition name="fade" mode="out-in" appear>
        <div class="full-height-dynamic relative px-3 md:px-6 py-3 md:py-6 flex flex-col items-center md:items-stretch gap-8 h-screen w-full overflow-y-auto antialiased">
            <div class="top-row place-items-center md:px-4">
                <Transition name="fade">
                    <ProgressBar
                        class="top-row-progress"
                        :current-step="step"
                        :question-index="currentQuestionIndex"
                        :steps="STEP"
                        :is-answered="questionsComponentRef?.isAnswered" />
                </Transition>
                <Transition name="fade">
                    <div v-if="showSkip" class="top-row-skip place-self-end">
                        <BaseButton
                            theme="secondary"
                            type="button"
                            :disabled="isLoading || isRecordingProcessing"
                            class="!pl-0 py-3 !pr-2 md:!pr-5"
                            data-cy="skip-personalization"
                            @click="showSkipDialog = true">
                            Skip <i class="bi bi-chevron-right icon-bold" />
                        </BaseButton>
                    </div>
                </Transition>
            </div>

            <Bubbles direction="bottom" :is-footer="true" />
            <div class="md:order-1 w-full z-30 flex flex-col items-center justify-end md:gap-4 flex-1">
                <!-- Main content -->
                <div class="w-full flex flex-col justify-center items-center gap-4 flex-1">
                    <div class="w-16 h-16">
                        <CoachingModeMarble />
                    </div>
                    <Personalization v-if="step === STEP.PERSONALIZATION" :errors="errors?.personalization" @submit="onPersonalizationSubmit" />

                    <div v-else-if="step == STEP.GREETING">
                        <div class="flex flex-col items-center gap-[60px]">
                            <div class="text-center leading-tight">
                                <div class="max-w-xl heading-1">
                                    <div class="mb-3">
                                        <span class="gradient-text">Hi {{ user_details.first_name }}, I'm Nadia.</span>
                                        <br />A safe space to think, reflect, and grow together.
                                    </div>
                                    <p class="text-[#8C8C8C] text-center heading-3">
                                        I'm here to help you navigate work challenges and enhance your impact, as a {{ role
                                        }}{{ organizationDetails.is_default ? "" : ` at ${organizationDetails.name}` }}.
                                    </p>
                                </div>
                            </div>
                            <BaseButton theme="primary" class="w-[300px] leading-6 body-1" data-cy="get-started" @keydown.enter="getStarted" @click="getStarted">
                                Get started
                            </BaseButton>
                        </div>
                    </div>
                    <Questions
                        v-else-if="step === STEP.QUESTIONS"
                        ref="questionsComponentRef"
                        :disabled="isLoading"
                        :is-loading="isLoading"
                        :error="error"
                        :current-question="currentQuestion"
                        @answer="handleQuestionAnswer"
                        @next="handleNextQuestion"
                        @dismiss-error="error = null"
                        @transcribe-error="handleErrorFallback" />
                    <Insights v-else-if="step === STEP.INSIGHTS" :insights="insights" @done="handleDone" @checkin="handleCheckin" />
                    <ScheduleCheckin
                        v-else-if="step === STEP.CHECKIN"
                        :redirect-path="post_onboarding_redirect"
                        :title="insight_checkin.title || 'Nadia Check-in'"
                        :description="insight_checkin.content || ''" />
                </div>
                <!-- Start of perceived footer -->
                <div class="grid grid-cols-3 items-center mt-8 md:my-6 w-full">
                    <div v-if="showPrivacyDialog" :class="isRecordingProcessing || isLoading ? 'hidden md:block' : ''">
                        <PrivacyDialog button-text="Privacy" :disabled="isRecordingProcessing || isLoading" />
                    </div>
                    <div class="flex text-center justify-center flex-wrap w-full gap-x-2">
                        <p class="gradient-text text-sm md:text-base font-semibold inline">Made by</p>
                        <img :src="ColorLogo" alt="logo" class="h-6 w-auto" />
                    </div>
                    <form class="flex flex-col gap-5 items-center" @change.prevent="handleLanguagePreference">
                        <div class="gap-10 flex items-center justify-center">
                            <select v-model="userLanguage" class="focus-visible:outline-none form-control-select text-base leading-6">
                                <template v-for="opt in language_options" :key="opt[0]">
                                    <option :value="opt[0]" :selected="opt[0] === userLanguage" v-text="opt[0]"></option>
                                </template>
                            </select>
                        </div>
                        <div v-if="errors?.language_preference?.default_language" class="body-1 text-red-700">{{ errors.language_preference.default_language }}</div>
                    </form>
                </div>
            </div>
        </div>
    </Transition>
    <CookieBanner />
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import ColorLogo from "~img/color-logo.svg";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseDialog from "~vue/components/BaseDialog.vue";
import Bubbles from "~vue/components/Bubbles.vue";
import CookieBanner from "~vue/components/CookieBanner.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import Insights from "~vue/Onboarding/Insights.vue";
import Personalization from "~vue/Onboarding/Personalization.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import ProgressBar from "~vue/Onboarding/ProgressBar.vue";
import Questions from "~vue/Onboarding/Questions.vue";
import ScheduleCheckin from "~vue/Onboarding/ScheduleCheckin.vue";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, useTemplateRef } from "vue";

const STEP = {
    PERSONALIZATION: "personalization",
    QUESTIONS: "questions",
    INSIGHTS: "insights",
    OUTRO: "outro",
    CHECKIN: "checkin",
    GREETING: "greeting",
};

const FADE_DELAY_MS = 500;

const { $setEventContext } = inject("globalProperties");
const props = defineProps({
    errors: Object,
    language_options: Array,
    default_language: String,
    insights: Array,
    step: String,
    event_context: Object,
    user_details: Object,
    post_onboarding_redirect: String,
    role: String,
    insight_checkin: {
        type: { title: String, content: String },
        default: () => ({ title: "", content: "" }),
    },
});

const error = ref(null);
const isLoading = ref(false);
const questionsComponentRef = useTemplateRef("questionsComponentRef");
const userLanguage = ref(props.default_language);

const organizationDetails = inject("organization_details");

const questions = ref([
    {
        title: "I can help you best when I understand your work world",
        text: "Tell me more about your day to day? What are you currently working on, and what does your team look like?",
        answer: null,
        llmResponse: null,
        offTopic: false,
    },
    {
        title: "Let's focus on what matters most to you right now",
        text: "Has anyone given you feedback recently that has stuck with you? Or are there specific areas you want to grow in that we can focus on first?",
        answer: null,
        llmResponse: null,
        offTopic: false,
    },
]);

const currentQuestionIndex = ref(0);
const currentQuestion = computed(() => questions.value[currentQuestionIndex.value]);
const showSkip = computed(() => props.step === STEP.QUESTIONS);
const showPrivacyDialog = computed(() => {
    // PrivacyDialog is more integrated in the personalization and outro step, so it's added there.
    return [STEP.PERSONALIZATION, STEP.QUESTIONS, STEP.INSIGHTS, STEP.CHECKIN, STEP.GREETING].includes(props.step);
});

const isRecordingProcessing = computed(() => questionsComponentRef.isProcessing);
const isLastQuestion = computed(() => currentQuestionIndex.value === questions.value.length - 1);

const showSkipDialog = ref(false);

onMounted(() => {
    $setEventContext(props.event_context);
});

onUnmounted(() => {
    $setEventContext({});
});

function handleNextQuestion() {
    if (isLastQuestion.value) {
        isLoading.value = true;
        questions.value[currentQuestionIndex.value].showMemoryAnimation = true;
        /*
         * Allow the saved memory animation to run completely
         */

        postResponse(
            {
                intent: "insights",
                form: questions.value.map((q) => ({ question: q.text, answer: q.answer })),
            },
            {
                only: ["step", "errors", "insights"],
                onFinish: () => {},
            },
        );
    } else {
        nextTick(() => {
            questions.value[currentQuestionIndex.value].showMemoryAnimation = true;
            currentQuestionIndex.value++;
        });
    }
}

async function handleQuestionAnswer({ answer, append, onFinish, usingMic }) {
    let nextAnswer;
    isLoading.value = true;
    error.value = null;

    const currentAnswer = questions.value[currentQuestionIndex.value].answer;
    if (append && currentAnswer !== null) {
        nextAnswer = `${currentAnswer}\n\n${answer}`;
    } else {
        logUserInteraction(`onboarding_q${currentQuestionIndex.value + 1}_completed`, { mic: usingMic });
        nextAnswer = answer;
    }

    try {
        const response = await fetch("/coach/active_listening", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                form: {
                    question: questions.value[currentQuestionIndex.value].text,
                    answer: nextAnswer,
                },
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to obtain active_listening response");
        }

        const payload = await response.json();

        if (payload.status === "guardrails_violated") {
            questions.value[currentQuestionIndex.value].offTopic = true;
            return;
        }

        questions.value[currentQuestionIndex.value].answer = nextAnswer;
        questions.value[currentQuestionIndex.value].llmResponse = payload.content;
        questions.value[currentQuestionIndex.value].offTopic = false;
    } catch (e) {
        logError(e);
        handleErrorFallback(new Error("I am having trouble processing your answer. Please try again"));
    } finally {
        isLoading.value = false;
        onFinish();
    }
}

function handleErrorFallback(e) {
    error.value = e;
    logError(e);
}

function postResponse(payload, { only = [], onFinish = () => {}, errorBag } = {}) {
    isLoading.value = true;

    router.post("", payload, {
        only,
        // Only need to load this once in the first request
        except: ["language_options"],
        errorBag,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
        },
        onFinish: () => {
            isLoading.value = false;
            onFinish();
        },
    });
}

function handleDone({ onFinish } = {}) {
    postResponse({ intent: "done" }, { only: ["step", "user_details"], onFinish });
}

function handleCheckin({ insight_checkin, onFinish }) {
    postResponse({ intent: "checkin", insight_checkin }, { only: ["step", "user_details", "insight_checkin", "post_onboarding_redirect"], onFinish });
}

function handleSkip() {
    postResponse({ intent: "skip" });
}

function onPersonalizationSubmit(formValues) {
    postResponse({ intent: "personalization", form: formValues }, { only: ["step", "errors", "user_details", "role"], errorBag: "personalization" });
}

function getStarted() {
    postResponse({ intent: "get_started" });
}

function handleLanguagePreference() {
    postResponse({ intent: "language_preference", form: { default_language: userLanguage.value } }, { only: ["errors", "default_language"], errorBag: "language_preference" });
}
</script>

<style type="postcss">
.error-list li {
    @apply text-xs tracking-tighter font-medium text-red-700 text-left;
}

.button {
    @apply flex justify-center items-center gap-1 transition-colors ease-in duration-150 rounded-[20px] px-9 py-3 leading-none font-semibold tracking-tighter text-base w-full ring-2 ring-inset;
}

.button-text {
    @apply md:px-6 px-0 py-3 transition-colors ease-in duration-150 font-medium text-base leading-none ring-0;
}

.button-text:not(.button-text--muted) {
    @apply text-[#555BA2] hover:text-[#4B508F];
}

.button-text--muted {
    @apply hover:text-[#7C7C7C] text-[#8C8C8C];
}

.button-primary {
    @apply bg-[#555BA2] hover:bg-[#4B508F] hover:ring-[#4B508F] text-white ring-[#555BA2];
}

.button-danger {
    @apply text-white ring-[#CF1322] bg-[#CF1322];
}

.button-primary:disabled,
.button-danger:disabled {
    @apply bg-[#F5F5F5] ring-[#F5F5F5] text-[#8C8C8C];
}

.button-text:disabled {
    @apply opacity-40;
}

@media (min-width: 768px) {
    .gradient-background {
        background: linear-gradient(360deg, #e9defa 0%, #fff6eb 100%);
    }
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity v-bind(FADE_DELAY_MS + "ms") ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* The following are needed for the language dropdown in the "footer" */
.form-control {
    @apply flex flex-col gap-1;
}

.form-control-select {
    @apply bg-transparent shrink-0 inline-block w-auto text-base font-medium tracking-tighter hover:text-[#7C7C7C] text-[#8C8C8C];
}

.form-control-select option {
    @apply text-gray-700;
}

.form-control-input {
    @apply w-full p-3 leading-none outline-[#555BA2] rounded-xl border-2 tracking-tighter text-base font-semibold text-gray-600 border-[#E8E8E8];
}

.top-row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 20px;
}

.top-row-progress {
    grid-column: span 6 / span 6;
    grid-column-start: 4;
    grid-row-start: 1;
}

.top-row-skip {
    grid-column: span 3 / span 3;
    grid-column-start: 10;
    grid-row-start: 1;
}
</style>
