<template>
    <Transition name="fade">
        <PermissionDialog :is-open="showMicPermissionAide" @cancel="handleDialogCancel" />
    </Transition>
    <RecordingTestDialog @dismiss="usingText = true" @mic-selected="handleMicSelection" />
    <div class="w-full flex flex-col gap-6 md:gap-10">
        <div v-if="!isAnswered" class="flex flex-col gap-3 max-w-[440px] mx-auto text-center">
            <p class="text-[#8C8C8C] body-1">{{ currentQuestion.title }}</p>
            <h2 class="heading-3 !font-medium text-[#262626] leading-[normal]">{{ currentQuestion.text }}</h2>
        </div>
        <ChatErrorMessage v-if="error" class="md:max-w-[800px] mx-auto" :error-message="error.message" @dismiss="$emit('dismiss-error')" />
        <div
            class="md:max-w-[800px] w-full mx-auto grow bg-white flex md:flex-row flex-wrap flex-col items-start md:items-center gap-4 md:gap-1 justify-between rounded-3xl px-6 transition-color"
            :class="[isRecording ? 'py-5' : 'py-6', isAnswered && !isLoading ? 'gradient-border' : 'border-[#E8E8E8] border-2']">
            <template v-if="showLoader">
                <div class="text-left h-10 body-1 gradient-text flex items-center gap-3">Thinking... <LoadingSpinner class="h-4 text-[#8C8C8C]" /></div>
            </template>
            <template v-else-if="isAnswered">
                <div class="grow flex flex-col gap-10 justify-between">
                    <div class="grow chat-1 text-[#262626]">
                        <template v-if="currentQuestion.offTopic"> Let’s get back on track and discuss topics about your role, your position, and your team at work. </template>
                        <template v-else>
                            {{ currentQuestion.llmResponse }}
                        </template>
                    </div>
                    <div class="body-1 text-[#8C8C8C] flex flex-row-reverse md:flex-row items-center justify-between">
                        <div class="hidden md:block">Just to make sure - did I get this right?</div>
                        <button type="button" class="transition-color hover:text-[#555BA2] flex gap-1 items-center" @click="handleMoreContext">
                            <i v-if="usingText" class="bi bi-pencil text-xs" />
                            <span v-else class="h-8 w-8">
                                <MicrophoneCircle background-class="fill-transparent" foreground-class="stroke-[currentColor]" />
                            </span>
                            <template v-if="currentQuestion.offTopic"> Edit response </template>
                            <template v-else> Add more context </template>
                        </button>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="grow">
                    <BaseTextarea
                        v-if="usingText"
                        ref="baseTextareaRef"
                        v-model:input="text"
                        placeholder="Type your answer here"
                        :disabled="disabled"
                        class="caret-[#555BA2] text-base md:text-[20px] leading-[normal] tracking-[-0.8px] text-[#262626]"
                        @keydown.enter.prevent="handleText"></BaseTextarea>
                    <div v-else class="w-full flex items-center gap-6">
                        <div>
                            <VoiceLevels
                                :class="{ 'opacity-40': !isRecording }"
                                class="py-4"
                                :bar-size="isRecording ? BAR_SIZE.SMALL : BAR_SIZE.TINY"
                                :levels="levelHistory"
                                :levels-scale-factor-fn="levelsScaleFactor"
                                :levels-to-display="VOICE_LEVELS_TO_DISPLAY"></VoiceLevels>
                        </div>
                        <div v-if="isRecording" class="text-[#8C8C8C] body-1">
                            <template v-if="isSilent"> No sound detected </template>
                            <template v-else> Listening... </template>
                        </div>
                    </div>
                </div>
                <div class="self-end transition-color flex md:justify-between items-center" :class="[hasAnswer ? 'gap-3' : 'gap-6', usingText ? 'self-end' : '']">
                    <template v-if="usingText">
                        <button data-testid="voice-toggle" type="button" class="hover:text-[#4B508F] text-[#555BA2] body-1 flex gap-1 items-center" @click="usingText = false">
                            <span class="h-8 w-8">
                                <MicrophoneCircle background-class="fill-transparent" foreground-class="stroke-[currentColor]" />
                            </span>
                            <template v-if="!text"><span class="hidden md:inline">Switch to voice</span><span class="md:hidden">Voice</span></template>
                        </button>
                        <button
                            data-testid="text-submit"
                            title="Submit"
                            :disabled="!text || disabled"
                            type="button"
                            class="group/button text-[#555BA2] h-10 w-10"
                            @click="handleText">
                            <ArrowCircle
                                direction="up"
                                background-class="group-disabled/button:opacity-[.24] group-hover/button:md:fill-[#4B508F] fill-[#555BA2]"
                                foreground-class="group-disabled/button:fill-[#555BA2] group-disabled/button:stroke-[#555BA2] stroke-white fill-white" />
                        </button>
                    </template>
                    <template v-else>
                        <template v-if="isRecording">
                            <button data-testid="record-cancel" type="button" title="Cancel" class="text-[#8C8C8C] hover:text-[#5E5E5E]" @click="cancelRecording">
                                <i class="bi bi-x-lg text-sm" />
                            </button>
                            <button
                                type="button"
                                data-testid="record-stop"
                                class="hover:bg-[#a70d19] bg-[#CF1322] hover:ring-[#a70d19] text-white ring-[#CF1322] rounded-3xl flex items-center gap-1 body-1 py-2 px-6"
                                @click="stopRecording">
                                <span class="h-6 w-6">
                                    <StopCircle background-class="fill-transparent" foreground-class="fill-[currentColor]" />
                                </span>
                                Stop
                            </button>
                        </template>
                        <template v-else>
                            <button
                                data-testid="text-toggle"
                                type="button"
                                :disabled="isRecording"
                                class="hover:text-[#4B508F] text-[#555BA2] body-1 flex gap-3 items-center disabled:text-[#8C8C8C]"
                                @click="usingText = true">
                                <span class="h-4 w-4">
                                    <Keyboard foreground-class="fill-[currentColor]" />
                                </span>
                                <template v-if="!isRecording"><span class="hidden md:inline">Switch to text</span><span class="md:hidden">Text</span></template>
                            </button>
                            <button
                                data-testid="record-start"
                                type="button"
                                class="bg-[#555BA2] hover:bg-[#4B508F] hover:ring-[#4B508F] text-white ring-[#555BA2] rounded-3xl flex items-center gap-1 body-1 py-2 px-6"
                                @click="startRecording">
                                <span class="h-6 w-6">
                                    <MicrophoneCircle background-class="fill-transparent" foreground-class="stroke-[currentColor]" />
                                </span>
                                Talk
                            </button>
                        </template>
                    </template>
                </div>
            </template>
        </div>

        <Transition name="fade">
            <div v-if="isAnswered && !currentQuestion.offTopic" class="inline-block mx-auto">
                <button type="button" :disabled="isLoading" class="button button-primary" @click="handleNextQuestion">Continue</button>
            </div>
            <template v-else-if="!isAnswered && !isLoading && !error">
                <ContextMeterText v-if="usingText && hasAnswer" :text="text" />
                <ContextMeter v-else-if="isRecording" :running="contextMeterRunning" />
            </template>
        </Transition>
    </div>
</template>

<script setup>
import { AudioRecorder } from "/js/AudioRecorder.js";
import { encodeAudio, transcribeRecording } from "/js/transcription.js";
import { useThrottleFn } from "@vueuse/core";
import ChatErrorMessage from "~vue/ChatErrorMessage.vue";
import BaseTextarea from "~vue/components/form/BaseTextarea.vue";
import VoiceLevels, { BAR_SIZE } from "~vue/components/VoiceLevels.vue";
import { CHAT_EVENT } from "~vue/events";
import ArrowCircle from "~vue/icons/ArrowCircle.vue";
import Keyboard from "~vue/icons/Keyboard.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import MicrophoneCircle from "~vue/icons/MicrophoneCircle.vue";
import StopCircle from "~vue/icons/StopCircle.vue";
import ContextMeter from "~vue/Onboarding/ContextMeter.vue";
import ContextMeterText from "~vue/Onboarding/ContextMeterText.vue";
import RecordingTestDialog from "~vue/RecordingTestDialog.vue";
import { isInIFrame } from "~vue/utils";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, nextTick, onUnmounted, ref, useTemplateRef, watch } from "vue";

import PermissionDialog from "./PermissionDialog.vue";

const SILENCE_TIMEOUT_MS = 5000;
const VOICE_LEVELS_TO_DISPLAY = 24;

const props = defineProps({
    error: { type: Error, default: null },
    disabled: { type: Boolean, default: false },
    currentQuestion: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
});

const emit = defineEmits(["answer", "dismiss-error", "next", "transcribe-error"]);

const { $sendEvent, emitter } = inject("globalProperties");

const STATE = {
    QUESTION: "question",
    RECORDING: "recording",
    TRANSCRIBING: "transcribing",
    ANSWER: "answer",
};

const baseTextareaRef = useTemplateRef("baseTextareaRef");
const contextMeterRunning = ref(false);
const recordingLevelSamplingRate = ref(100);
const text = ref("");
const usingText = ref(false);
const state = ref(STATE.QUESTION);
const showMicPermissionAide = ref(false);
const recorder = ref(null);
const levelHistory = ref([]);
const silenceTimeout = ref(null);

const isRecording = computed(() => state.value === STATE.RECORDING);
const isTranscribing = computed(() => state.value === STATE.TRANSCRIBING);
const isAnswered = computed(() => state.value === STATE.ANSWER);
const showLoader = computed(() => isTranscribing.value || props.isLoading);
const isSilent = computed(() => silenceTimeout.value !== null);
const isProcessing = computed(() => isRecording.value || isTranscribing.value);
const shouldAppend = computed(() => !!props.currentQuestion.answer && !props.currentQuestion.offTopic);

const hasAnswer = computed(() => {
    if (usingText.value) {
        return text.value.length > 0;
    }

    return isRecording.value;
});

watch(usingText, (value) => {
    if (!value) {
        return;
    }

    nextTick(focusTextInput);
});

watch(isRecording, (value) => {
    if (value) {
        emit("dismiss-error");
    }
});

watch(
    () => props.currentQuestion,
    () => {
        state.value = STATE.QUESTION;
        text.value = "";
    },
);

watch(state, (value) => {
    if (value === STATE.QUESTION && usingText.value) {
        nextTick(focusTextInput);
    }
});

watch(
    () => props.error,
    (value) => {
        if (value) {
            handleErrorFallback();
        }
    },
);

onUnmounted(() => {
    if (recorder.value) {
        clearRecorder();
    }
});

function focusTextInput() {
    baseTextareaRef.value?.textareaRef?.focus();
}

function levelsScaleFactor(breakpoints) {
    if (breakpoints.lg || breakpoints.md) {
        return 1;
    }

    return isRecording.value ? 0.7 : 0.9;
}

function handleErrorFallback() {
    state.value = STATE.QUESTION;
    usingText.value = true;
}

function handleDialogCancel() {
    showMicPermissionAide.value = false;
    usingText.value = true;
    logUserInteraction("use_text", {});
}

async function startRecording() {
    recorder.value = new AudioRecorder({ levelCheckInteralMs: 16 });
    recorder.value.on("level", handleRecorderLevel);
    recorder.value.on("finish", (data) => handleRecorderFinished(data));
    recorder.value.on("start", handleRecorderStart);
    recorder.value.on("stop", handleRecorderStop);
    recorder.value.on("silence", handleSilence);

    try {
        let viewedPrompt = false;
        await recorder.value.start((micPermissionState) => {
            if (micPermissionState === "prompt" || isInIFrame()) {
                showMicPermissionAide.value = true;
                viewedPrompt = true;
            }
        });

        /*
         *Reset any draft text that may have existed.before switching
         * to voice
         */
        if (text.value) {
            text.value = "";
        }

        if (viewedPrompt) {
            // User allowed mic after prompt if it doesn't error above
            logUserInteraction("allowed_mic", {});

            /*
             * User may have chosen "I'd rather type" in the permission dialog
             * but still grant permission in the browser prompt. In this case,
             * we should not continue with a recording because the user
             * chose to use text.
             */
            if (usingText.value) {
                recorder.value.stop({ abort: true });
                return;
            }
        }
    } catch (e) {
        usingText.value = true;
        openTestDialog();
        logUserInteraction("use_text", {});
    } finally {
        if (showMicPermissionAide.value) {
            showMicPermissionAide.value = false;
        }
    }
}

function clearRecorder() {
    recorder.value.off("level", handleRecorderLevel);
    recorder.value.off("finish", (data) => handleRecorderFinished(data));
    recorder.value.off("start", handleRecorderStart);
    recorder.value.off("stop", handleRecorderStop);
    recorder.value.off("silence", handleSilence);
    recorder.value = null;
}

function stopRecording() {
    recorder.value.stop();
}

function cancelRecording() {
    recorder.value.stop({ abort: true });
    levelHistory.value = [];
    state.value = STATE.QUESTION;
}

function handleText() {
    emit("answer", {
        answer: text.value,
        append: shouldAppend,
        onFinish: () => {
            state.value = STATE.ANSWER;
        },
        usingMic: false,
    });
}

function handleRecorderStart() {
    state.value = STATE.RECORDING;
}

async function handleRecorderFinished({ blob }) {
    clearRecorder();
    levelHistory.value = [];
    state.value = STATE.TRANSCRIBING;

    const encodedAudio = await encodeAudio(blob);
    try {
        const transcript = await transcribeRecording({
            encodedAudio,
            sendEvent: $sendEvent,
        });
        /*
         * Persist transcript as text answer in case there is an upstream
         * error when submitting answer.
         */
        text.value = transcript;

        emit("answer", {
            answer: transcript,
            append: shouldAppend,
            onFinish: () => {
                state.value = STATE.ANSWER;
            },
            usingMic: true,
        });
    } catch (e) {
        const error = new Error("I am having trouble listening to you. Please try again.");
        emit("transcribe-error", error);
        logError(e);
    }
}

function openTestDialog() {
    emitter.emit(CHAT_EVENT.OPEN_MIC_TEST_DIALOG);
}

function clearSilenceTimeout() {
    if (silenceTimeout.value) {
        window.clearTimeout(silenceTimeout.value);
        silenceTimeout.value = null;
    }
}

function handleRecorderStop() {
    contextMeterRunning.value = false;
    clearSilenceTimeout();
}

function handleSilence() {
    silenceTimeout.value = window.setTimeout(() => {
        cancelRecording();
        openTestDialog();
    }, SILENCE_TIMEOUT_MS);
}

function handleNextQuestion() {
    emit("next");
}

function handleMoreContext() {
    if (usingText.value) {
        text.value = "";
        state.value = STATE.QUESTION;
    } else {
        startRecording();
    }

    logUserInteraction("give_more_context_clicked", {});
}

const updateContextMeterSampling = useThrottleFn(({ isBelowSilenceThreshold }) => {
    /*
     * Check whether user is silent. Context meter should only run
     * when we detect signal.
     * If silence is detected, then the sampling rate is
     * increased so that signal is detected sooner. If signal is detected,
     * the sampling rate is decreased to accomodate natural pausing.
     */
    contextMeterRunning.value = !isBelowSilenceThreshold;
    if (isBelowSilenceThreshold) {
        recordingLevelSamplingRate.value = 200;
    } else {
        recordingLevelSamplingRate.value = 4000;
    }
}, recordingLevelSamplingRate);

function handleRecorderLevel(level) {
    const isBelowSilenceThreshold = recorder.value.isBelowSilenceThreshold(level);
    if (!isBelowSilenceThreshold) {
        clearSilenceTimeout();
    }
    levelHistory.value.push(level);

    updateContextMeterSampling({ isBelowSilenceThreshold });
}

function handleMicSelection(id) {
    recorder.value?.setAudioInputDeviceId(id);
    startRecording();
}

defineExpose({ handleMicSelection, cancelRecording, isProcessing, isAnswered });
</script>

<style scoped>
textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

textarea::-webkit-scrollbar,
textarea::-webkit-resizer {
    display: none;
}
</style>
