<template>
    <div class="flex flex-col items-center gap-3">
        <div class="overflow-hidden w-36 h-2 bg-[#555BA2]/[0.24] rounded-full mx-auto">
            <div class="progress h-2 rounded-full bg-[#555BA2]" :style="{ width: `${percentage}%` }"></div>
        </div>
        <Transition name="fade" mode="out-in" appear>
            <div class="min-h-[24px] font-medium text-base text-[#555BA2]" :key="message">{{ message }}</div>
        </Transition>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    percentage: { type: Number, default: 1 },
    currentCheckpoint: { type: Number, default: -1 },
});

const message = computed(() => {
    switch (props.currentCheckpoint) {
        case 0:
            return "Can you tell me more?";

        case 1:
            return "Great start! Any specific examples?";

        case 2:
            return "I'm getting a clearer picture. Anything else?";

        case 3:
            return "Excellent detail! This helps me understand deeply.";

        default:
            return "";
    }
});
</script>

<style scoped>
.progress {
    transition: width 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
