<template>
    <div class="text-center leading-tight">
        <div class="max-w-xl heading-1">
            <span class="gradient-text">Meet Nadia,</span>
            <br />your new personalized coach and thought partner
            <p class="text-[#8C8C8C] text-center heading-3 mt-3">Nadia will begin to personalize based on your name and role</p>
        </div>
    </div>
    <form class="flex flex-col gap-10 md:gap-[60px] items-center w-full max-w-xl mt-6" @submit.prevent="onSubmit">
        <div class="flex flex-col gap-2 w-full justify-center">
            <div class="form-control">
                <input v-model="userName" required type="text" class="form-control-input p-6" placeholder="Name" />
                <div v-if="formFieldHasError('name')" class="body-1 text-red-700">{{ errors.name }}</div>
            </div>
            <div class="form-control">
                <input v-model="userRole" required type="text" class="form-control-input" placeholder="Role" />
                <div v-if="formFieldHasError('job_title')" class="body-1 text-red-700">{{ errors.job_title }}</div>
            </div>
        </div>
        <BaseButton theme="primary" class="w-[300px] leading-6 body-1" type="submit" data-cy="submit"> Meet Nadia </BaseButton>
    </form>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import { ref } from "vue";

const props = defineProps({
    errors: Object,
});

const emit = defineEmits(["submit"]);
const userName = ref("");
const userRole = ref("");

function formFieldHasError(field) {
    return props.errors && Object.hasOwn(props.errors, field);
}

function onSubmit() {
    emit("submit", {
        name: userName.value,
        job_title: userRole.value,
    });
}
</script>

<style scoped>
.form-control {
    @apply flex flex-col gap-1;
}

.form-control-select {
    @apply bg-transparent shrink-0 inline-block w-auto text-base font-medium tracking-tighter hover:text-[#7C7C7C] text-[#8C8C8C];
}

.form-control-select option {
    @apply text-gray-700;
}

.form-control-input {
    @apply w-full p-6 leading-none outline-[#555BA2] rounded-xl border-2 tracking-tighter text-base font-semibold text-gray-600 border-[#E8E8E8] bg-white;
}
</style>
