<template>
    <!-- Dialog -->
    <BaseDialog v-if="seeMoreDialog" class="md:!rounded-3xl" @cancel="closeDialog" @close="closeDialog">
        <InsightsCard
            card-type="dialog"
            :title="dialogInsight.title"
            :subtitle="dialogInsight.subtitle"
            :content="dialogInsight.content"
            @close="closeDialog"
            @dive-deeper="diveDeeper(dialogInsight.topic_id)" />
    </BaseDialog>
    <div class="flex flex-col gap-10 w-full items-center justify-center">
        <!-- Header text -->
        <div class="flex flex-col gap-2 justify-center items-center">
            <p class="text-[#8C8C8C] body-1 tracking-[-0.64px]">Based on what you shared</p>
            <div class="text-center leading-tight">
                <div class="max-w-xl heading-1">
                    <span class="gradient-text">I have some thoughts</span>
                    <br />to guide our journey together
                </div>
            </div>
        </div>
        <div class="grid md:grid-cols-1 lg:grid-cols-3 md:gap-3 gap-2">
            <!-- Insight card -->
            <InsightsCard
                v-for="insight in insights"
                :id="insight.topic_id"
                :key="insight"
                :title="insight.title"
                :subtitle="insight.subtitle"
                :content="insight.content"
                card-type="onboarding"
                @close="closeDialog"
                @see-more="showDialog(insight.topic_id)"
                @dive-deeper="diveDeeper(insight.topic_id)" />
        </div>
        <div class="flex flex-col items-center gap-3 mb-10">
            <BaseButton class="!rounded-3xl w-[300px] h-[60px] bg-white m-2" @click="talkAboutSomethingElse">
                <p class="font-semibold">Talk about something else on my mind</p>
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import BaseDialog from "~vue/components/BaseDialog.vue";
import { openUrl } from "~vue/utils";
import { CHATSOURCE } from "~vue/utils/constants.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { onMounted, ref } from "vue";

import InsightsCard from "./InsightsCard.vue";

const props = defineProps({
    insights: { type: Array, required: true },
});

const dialogInsight = ref(null);
const seeMoreDialog = ref(false);

function showDialog(id) {
    const insight = getInsightById(id);
    seeMoreDialog.value = true;
    dialogInsight.value = insight;
}

function closeDialog() {
    seeMoreDialog.value = false;
    dialogInsight.value = null;
}

function getInsightById(id) {
    return props.insights.find((insight) => insight.topic_id === id);
}

onMounted(() => {
    logUserInteraction(`onboarding_insights_viewed`);
});

function talkAboutSomethingElse() {
    openUrl("/coach/start/");
    logUserInteraction("onboarding_talk_about_something_else_click", { action: "talk_about_something_else" });
}

/** Creates a SuggestedTopic, whose id is passed as a URL search param to start a chat with the insight fed as context for a conversation starter. */
function diveDeeper(id) {
    const insight = getInsightById(id);
    openUrl(`/coach/start/?topic_id=${insight.topic_id}`, { queryParams: { chat_source: CHATSOURCE.ONBOARDING_DEEP_DIVE } });
    logUserInteraction("onboarding_insight_clicked", {
        action: "dive_deeper",
        type: "onboarding_insight",
        insight_title: insight.title,
        topic_id: insight.topic_id,
        content: insight.content,
    });
}
</script>
