<template>
    <div class="body-1 !font-semibold flex flex-col gap-2 items-center justify-center">
        <div class="md:hidden">{{ currentStepDisplay }}</div>
        <div v-if="currentStepDisplay" class="border-2 border-[#E8E8E8] rounded-full px-6 py-3 bg-white flex justify-between gap-2">
            <Transition name="fade" mode="out-in">
                <div :key="currentStepDisplay" class="hidden md:block whitespace-nowrap">{{ currentStepDisplay }}</div>
            </Transition>
            <div class="flex gap-1 items-center py-2 md:py-[unset]">
                <div v-for="step in 3" :key="step" class="h-2 rounded-full relative w-10 bg-[#555BA21F]" :style="{ width: `${BAR_WIDTH}px` }">
                    <div class="absolute h-2 rounded-full bg-[#555BA2] transition-all" :style="{ width: currentProgressWidth(step) }"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";

const BAR_WIDTH = 40;

const { currentStep, steps, questionIndex, isAnswered } = defineProps({
    currentStep: { type: String, required: true },
    steps: { type: Object, required: true },
    questionIndex: { type: Number, required: true },
    isAnswered: { type: Boolean, default: false },
});

const STEP_ORDER = { [steps.PERSONALIZATION]: 1, [steps.GREETING]: 1, [steps.QUESTIONS]: 2, [steps.INSIGHTS]: 3 };

const currentProgressWidth = (step) => {
    if (STEP_ORDER[currentStep] > step) {
        return `${BAR_WIDTH}px`;
    }
    if (STEP_ORDER[currentStep] === step) {
        if (currentStep === steps.GREETING) {
            return `${BAR_WIDTH}px`;
        }
        if (currentStep === steps.QUESTIONS) {
            if (questionIndex === 0) {
                return isAnswered ? `${BAR_WIDTH / 2}px` : `${BAR_WIDTH / 5}px`;
            }
            return isAnswered ? `${BAR_WIDTH}px` : `${BAR_WIDTH / 2}px`;
        }
        return `${BAR_WIDTH / 2}px`;
    }
    return undefined;
};

const currentStepDisplay = computed(() => {
    switch (currentStep) {
        case steps.PERSONALIZATION:
        case steps.GREETING:
            return "Meet Nadia";
        case steps.QUESTIONS:
            return "About you";
        case steps.INSIGHTS:
            return "Insights";
        default:
            return undefined;
    }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
